import { Link } from "react-router-dom";

import {
  Button,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { useSelector } from "react-redux";

// Formik
import { useFormik } from "formik";
import * as Yup from "yup";

import React, { useState } from "react";
import { APIClient } from "../../utils/api_helper";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../Components/Common/MsgToast";

const api = new APIClient();

const RequestStatusModal = ({ isModalOpen, toggleModal, request, responseStatus }) => {
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrorMsg] = useState("");

  const { user } = useSelector((state) => ({
    user: state.Profile.user,
  }));

  // events validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      payment_request_id: request.reference,
      payment_request_status: responseStatus,
      password: "",
    },

    validationSchema: Yup.object({
      payment_request_id: Yup.string().required("Please enter your event name"),
      payment_request_status: Yup.string().required("Please select your status"),
    }),

    onSubmit: async (values) => {
      try {
        console.log(values, "VALUESS__REQUESTTS");
        setSuccessMsg("");

        setErrorMsg("");

        const data = await api.post("/transaction/charge/simply/payment-request/", values);

        setSuccessMsg(data.message);

        console.log(data, "REQUEST__STATS__DATAA");

        // setTimeout(() => {
        //   setIsModalActive(false);
        //   setActiveRequest("");
        // }, 2000);
        toggleModal();
      } catch (error) {
        console.log(error, "LOGIN");

        let errMsg = error?.response?.data?.message;

        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || "internal server occured";

        setErrorMsg(errMsg);

        console.log(errMsg, "ER__MERSG");
      }
    },
  });

  const isUserRequester = request?.requester?.uuid === user?.uuid;

  return (
    <React.Fragment>
      {/* Position Top */}
      <Modal
        id="topmodal"
        isOpen={isModalOpen}
        toggle={() => {
          toggleModal();
        }}
      >
        <ModalHeader
          className="modal-title"
          id="myModalLabel"
          toggle={() => {
            // tog_positionTop();
            toggleModal();
          }}
        >
          {responseStatus === "rejected" ? "Reject payment request" : ""}
          {responseStatus === "approved" ? "Approve payment request" : ""}
          {responseStatus === "cancelled" ? "cancel payment request" : ""}
        </ModalHeader>
        <ModalBody className="text-center p-5">
          {/* <lord-icon
            src="https://cdn.lordicon.com/pithnlch.json"
            trigger="loop"
            colors="primary:#121331,secondary:#08a88a"
            style={{ width: "120px", height: "120px" }}
          ></lord-icon> */}
          <div className="mt-4">
            <h4 className="mb">
              {" "}
              {responseStatus === "rejected" ? (
                <p>
                  <span className="d-inline-block   btn-outline-danger py-1">Reject </span> payment requests{" "}
                  {isUserRequester ? "To" : "From"}
                </p>
              ) : (
                ""
              )}
              {responseStatus === "approved" ? (
                <p>
                  <span className="d-inline-block   btn-outline-success py-1">Approve </span> payment requests from
                </p>
              ) : (
                ""
              )}
              {responseStatus === "cancelled" ? (
                <p>
                  <span className="d-inline-block   btn-outline-warning py-1">Cancel </span> payment requests{" "}
                  {user?.uuid === request?.requester?.uuid ? "To" : "From"}
                </p>
              ) : (
                ""
              )}
            </h4>

            <h4 className="mb-3">
              <img className="rounded-circle avatar-xl" src={request?.requester_cam} width="200" />
              <span className="d-inline-block   btn-outline-info py-1">
                {isUserRequester ? request?.requestee?.first_name : request?.requester?.first_name}
              </span>{" "}
              {isUserRequester ? request?.requestee?.last_name : request?.requester?.last_name}
            </h4>

            <div className="hstack gap-2 justify-content-center">
              <Link
                to="#"
                className="btn btn-link link-success fw-medium"
                onClick={() => {
                  // tog_positionTop();
                }}
              ></Link>
            </div>
          </div>

          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <ModalBody>
              <Input type="hidden" id="id-field" />
              <Row className="g-3">
                <div className="mb-4">
                  <Label className="form-label">Enter password to continue</Label>
                  <Input
                    name="password"
                    className="form-control"
                    placeholder="Enter password"
                    type="password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password || ""}
                    invalid={validation.touched.password && validation.errors.password ? true : false}
                  />
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type="invalid">
                      <div>{validation.errors.password}</div>
                    </FormFeedback>
                  ) : null}
                </div>
              </Row>
            </ModalBody>
            <ModalFooter>
              <div className="hstack gap-2 justify-content-end">
                <button type="button" className="btn btn-light" onClick={() => toggleModal()}>
                  {" "}
                  Close{" "}
                </button>

                {!validation.isSubmitting && responseStatus === "approved" ? (
                  <button type="submit" className="btn btn-success" id="add-btn">
                    Approve
                  </button>
                ) : (
                  ""
                )}

                {!validation.isSubmitting && responseStatus === "rejected" ? (
                  <button type="submit" className="btn btn-danger" id="add-btn">
                    Reject
                  </button>
                ) : (
                  ""
                )}

                {!validation.isSubmitting && responseStatus === "cancelled" ? (
                  <button type="submit" className="btn btn-warning" id="add-btn">
                    Cancel
                  </button>
                ) : (
                  ""
                )}

                {validation.isSubmitting && (
                  <Button disabled={validation.isSubmitting} color="info" className="btn-load">
                    <span className="d-flex align-items-center">
                      <span className="flex-grow-1 me-2">Loading...</span>
                      <Spinner size="sm" type="grow" className="flex-shrink-0" role="status">
                        {" "}
                        Loading...{" "}
                      </Spinner>
                    </span>
                  </Button>
                )}
              </div>
            </ModalFooter>
          </Form>
        </ModalBody>
        {errMsg ? <MsgToast msg={errMsg} color="danger" icon="ri-checkbox-circle-line" /> : null}
      </Modal>
      {successMsg ? <MsgToast msg={successMsg} color="success" icon="ri-checkbox-circle-line" /> : null}
    </React.Fragment>
  );
};

export default RequestStatusModal;
