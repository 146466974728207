import React, { useState } from "react";

import {
  Form,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  TabContent,
  TabPane,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  DropdownToggle,
  UncontrolledDropdown,
  Spinner,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import ProccessingLoader from "../../../Components/Loaders/ProccessingLoader";
import { useFormik } from "formik";
import * as Yup from "yup";
import { APIClient } from "../../../utils/api_helper";
import TextField from "../../../Components/Common/Fields/TextField";
import SelectField from "../../../Components/Common/Fields/SelectField";
import { useDispatch } from "react-redux";
import { getCards } from "../../../store/actions";
import Countdown from "react-countdown";
import DeleteModal from "../Modals/DeleteModal";

const api = new APIClient();

const WalletCards = ({ cards, cardOptions }) => {
  const dispatch = useDispatch();
  const [openAddCardModal, setOpenAddCardModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  // const [isCardDeleted, setIiCardDeleted] = useState("");

  const [modal, setModal] = useState(false);

  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrorMsg] = useState("");

  const [cardRef, setCardRef] = useState("");

  const [isSettingDefault, setIsSettingDefault] = useState(false);
  const [isDefaultCardOpen, setIsDefaultCardOpen] = useState(false);
  const [currentSelectedCard, setCurrentSelectedCard] = useState({});

  console.log(cardRef, "CARD--REFFFFFFFFFFFFFFFF");

  // useEffect(() => {
  //   dispatch(getCards());
  // }, [dispatch]);

  const [alert, setAlert] = useState({
    status: false,
    title: "Proccessing...",
    message: "Adding card, please wait...",
    class: "spinner-border spinner-border-lg",
    textClass: "text-primary",
  });

  const handleCardDelete = async (reference) => {
    try {
      console.log(reference, "DELETING__CARDD");
      // setIsSettingDefault(true);

      setAlert({
        status: true,
        title: "Deleting...",
        message: "Deleting card, please wait...",
        class: "spinner-border spinner-border-lg",
        textClass: "text-primary",
      });

      setErrorMsg("");
      setSuccessMsg("");
      const data = await api.delete(`/wallets/${reference}/card/`);
      console.log(data, "DELETEDDD__DATA");

      // setRequestSuccess(data.message);

      dispatch(getCards());

      setSuccessMsg(data.message);
      setErrorMsg("");

      setIsDeleteModalOpen(false);

      // toggleModal();

      setAlert({ ...alert, status: false });

      setTimeout(() => {
        setModal(!modal);
      }, 2000);
      // setIsSettingDefault(false);
    } catch (error) {
      console.log(error, "LOGIN");
      setErrorMsg("");
      // setIsSettingDefault(false);

      let errMsg = error?.response?.data?.message;

      console.log(errMsg, "ERRRRRRRRRRRR");
      errMsg = errMsg || "internal server occured";

      setErrorMsg(errMsg);

      console.log(errMsg, "ER__MERSG");

      setAlert({
        status: true,
        title: "Sorry!",
        message: errMsg,
        //class: "ri-information-line",
        class: "ri-error-warning-line ri-3xl",
        textClass: "text-primary",
        //back_url: "/accounts/plans",
        dismissable: true,
      });
    }
  };

  const setCardDefaultHandler = async (reference) => {
    try {
      console.log(reference, "REQUEST__STATS__DATAA");
      setIsSettingDefault(true);

      setAlert({
        status: true,
        title: "Proccessing...",
        message: "Adding card, please wait...",
        class: "spinner-border spinner-border-lg",
        textClass: "text-primary",
      });

      setErrorMsg("");
      setSuccessMsg("");
      console.log(reference, "VALUESS__REQUESTTS");
      const data = await api.post("/wallets/card/default/", { reference: reference });
      console.log(data, "REQUEST__STATS__DATAA");

      // setRequestSuccess(data.message);

      dispatch(getCards());

      setSuccessMsg(data.message);
      setErrorMsg("");

      // toggleModal();
      setIsDefaultCardOpen(false);

      setAlert({ ...alert, status: false });

      setTimeout(() => {
        setModal(!modal);
      }, 2000);
      setIsSettingDefault(false);
    } catch (error) {
      console.log(error, "LOGIN");
      setErrorMsg("");
      setIsSettingDefault(false);

      let errMsg = error?.response?.data?.message;

      console.log(errMsg, "ERRRRRRRRRRRR");
      errMsg = errMsg || "internal server occured";

      setErrorMsg(errMsg);

      console.log(errMsg, "ER__MERSG");

      setAlert({
        status: true,
        title: "Sorry!",
        message: errMsg,
        //class: "ri-information-line",
        class: "ri-error-warning-line ri-3xl",
        textClass: "text-primary",
        //back_url: "/accounts/plans",
        dismissable: true,
      });
    }
  };

  const [topBorderTab, settopBorderTab] = useState("1");
  const topBordertoggle = (tab) => {
    if (topBorderTab !== tab) {
      settopBorderTab(tab);
    }
  };

  const validateCard = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      accountNumber: "",
    },

    validationSchema: Yup.object({
      accountNumber: Yup.string().required("Please enter the account number"),
    }),

    onSubmit: async (values) => {
      try {
        console.log(values, "REQUEST__STATS__DATAA");

        setAlert({
          status: true,
          title: "Validating...",
          message: "Validating, please wait...",
          class: "spinner-border spinner-border-lg",
          textClass: "text-primary",
        });

        setErrorMsg("");
        setSuccessMsg("");
        console.log(values, "VALUESS__REQUESTTS");
        const data = await api.post("/wallets/card/validate/", values);
        console.log(data, "REQUEST__STATS__DATAA");

        setSuccessMsg(data.message);
        setErrorMsg("");

        setAlert({ ...alert, status: false });
        topBordertoggle("2");
      } catch (error) {
        console.log(error, "LOGIN");
        setErrorMsg("");

        let errMsg = error?.response?.data?.message;

        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || "internal server occured";

        setErrorMsg(errMsg);

        console.log(errMsg, "ER__MERSG");

        setAlert({
          status: true,
          title: "Sorry!",
          message: errMsg,
          //class: "ri-information-line",
          class: "ri-error-warning-line ri-3xl",
          textClass: "text-primary",
          //back_url: "/accounts/plans",
          //// redirect user to the customer portal
          dismissable: true,
        });
      }
    },
  });

  console.log(validateCard.errors, "VALIDATION__ERRORS");

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      accountNumber: currentSelectedCard?.account_number,
      type: currentSelectedCard?.type,
      description: currentSelectedCard?.description,
      otp_code: "",
    },

    validationSchema: Yup.object({
      accountNumber: Yup.string().required("Please enter the account number"),
      type: Yup.string().required("Please select your card type"),
      description: Yup.string().required("Please enter a description"),
    }),

    onSubmit: async (values) => {
      try {
        console.log(values, "REQUEST__STATS__DATAA");

        setAlert({
          status: true,
          title: "Proccessing...",
          message: "Adding card, please wait...",
          class: "spinner-border spinner-border-lg",
          textClass: "text-primary",
        });

        setErrorMsg("");
        setSuccessMsg("");
        console.log(values, "VALUESS__REQUESTTS");
        const data = await api.post("/wallets/card/", values);
        console.log(data, "REQUEST__STATS__DATAA");

        dispatch(getCards());

        setSuccessMsg(data.message);
        setErrorMsg("");

        validation.resetForm();

        // toggleModal();

        setAlert({ ...alert, status: false });

        setOpenAddCardModal(false);

        // setTimeout(() => {
        //  }, 2000);
      } catch (error) {
        console.log(error, "LOGIN");
        setErrorMsg("");

        let errMsg = error?.response?.data?.message;

        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || "internal server occured";

        setErrorMsg(errMsg);

        console.log(errMsg, "ER__MERSG");

        setAlert({
          status: true,
          title: "Sorry!",
          message: errMsg,
          //class: "ri-information-line",
          class: "ri-error-warning-line ri-3xl",
          textClass: "text-primary",
          //back_url: "/accounts/plans",
          //// redirect user to the customer portal
          dismissable: true,
        });
      }
    },
  });

  const productState = [
    { label: "MTN", value: "mtn" },
    { label: "VODAFONE", value: "vod" },
    { label: "AT", value: "tgo" },
  ];

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <div>
          <Button
            color="light"
            onClick={() => {
              topBordertoggle("1");
            }}
          >
            <small className="d-flex justify-content-center">resent otp</small>
          </Button>
        </div>
      );
    } else {
      // Render a countdown
      return (
        <span>
          <small className="d-flex justify-content-center">
            Resent otp in {hours}:{minutes}:{seconds}
          </small>
        </span>
      );
    }
  };

  const bgColors = {
    vodafone: "bg-soft-warning",
    mtn: "bg-soft-primary",
    at: "bg-soft-info",
  };

  console.log(cardOptions, "cardOptionscardOptions");

  console.log(cards, "CARRDSS");

  return (
    <React.Fragment>
      <Row>
        {cards?.map((item, key) => (
          <Col
            lg={4}
            key={key}
            // onClick={() => {
            //   if (!item.default) {
            //     openModal(item.reference);
            //     setOpenAddCardModal(true);
            //   }
            // }}
          >
            <Card className={`${bgColors[item?.type]} card-animate ribbon-box `}>
              {item?.default ? (
                <div className="ribbon-two ribbon-two-primary">
                  <span>Default</span>
                </div>
              ) : null}

              <CardBody>
                <u className="d-flex gap-1 d-flex justify-content-end">
                  {/* <li
                    onClick={() => {
                      setCardRef(item.reference);
                      setCurrentSelectedCard(item);

                      console.log(item.reference, "REFFF");

                      setOpenAddCardModal(true);
                    }}
                    className="edit d-flex d-flex justify-content-end"
                  >
                    <UncontrolledDropdown className="z-3">
                      <DropdownToggle href="#" className="btn-soft-secondary btn-sm dropdown" tag="button">
                        <i className="ri-add-fill align-middle" id="edit" />

                        <UncontrolledTooltip placement="top" target="edit">
                          edit
                        </UncontrolledTooltip>
                      </DropdownToggle>
                    </UncontrolledDropdown>
                  </li> */}

                  {!item?.default ? (
                    <li
                      onClick={() => {
                        if (!item?.default) {
                          setCardRef(item?.reference);

                          console.log(item?.reference, "REFFF");

                          setIsDefaultCardOpen(true);
                          setCurrentSelectedCard(item);
                        }
                      }}
                      className="edit d-flex d-flex justify-content-end"
                    >
                      <UncontrolledDropdown className="z-3">
                        <DropdownToggle href="#" className="btn-soft-secondary btn-sm dropdown" tag="button">
                          <i className="ri-layout-left-2-line align-middle" id="default" />

                          <UncontrolledTooltip placement="top" target="default">
                            Set as default
                          </UncontrolledTooltip>
                        </DropdownToggle>
                      </UncontrolledDropdown>
                    </li>
                  ) : null}

                  <li
                    onClick={() => {
                      setCardRef(item?.reference);
                      setCurrentSelectedCard(item);

                      console.log(item?.reference, "REFFF");

                      setIsDeleteModalOpen(true);
                    }}
                    className="edit d-flex d-flex justify-content-end"
                  >
                    <UncontrolledDropdown className="z-3">
                      <DropdownToggle href="#" className="btn-soft-secondary btn-sm dropdown" tag="button">
                        <i className="ri-delete-bin-2-line align-middle" id="delete" />

                        <UncontrolledTooltip placement="top" target="delete">
                          delete
                        </UncontrolledTooltip>
                      </DropdownToggle>
                    </UncontrolledDropdown>
                  </li>
                </u>
                <h5 className="text-muted text-uppercase fs-13">
                  {item.description}
                  <i className={"fs-18 float-end align-middle ri-wallet-3-line text-success"}></i>
                </h5>

                <p className="text-muted">
                  {item.account_number} <span className="text-muted fs-10 float-end">8/99</span>
                </p>
              </CardBody>
              <div className="progress animated-progess rounded-bottom rounded-0" style={{ height: "6px" }}>
                {cardOptions
                  ?.find((c) => c.type === item.type)
                  ?.progressBar?.map((item, key) => (
                    <div
                      className={"progress-bar rounded-0 " + item.bgColor}
                      role="progressbar"
                      style={{ width: item.width }}
                      aria-valuenow="30"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      key={key}
                    ></div>
                  ))}
              </div>
            </Card>
          </Col>
        ))}
      </Row>

      <Modal backdrop={"static"} id="topmodal" isOpen={openAddCardModal}>
        <ModalHeader
          className="modal-title"
          id="myModalLabel"
          toggle={() => {
            setOpenAddCardModal(false);
            topBordertoggle("1");
            validateCard.resetForm();
            validation.resetForm();
          }}
        ></ModalHeader>

        <ModalBody className="">
          <ModalBody className="login-modal p-">
            <h5 className="text-white fs-20">Manage card</h5>
            <p className="text-white-50 mb-4">Edit this card</p>

            {/* {!currentSelectedCard.default ? (
              <div className="vstack gap-2 justify-content-center">
                <button className="btn btn-light d-flex justify-content-center gap-1">
                  <i className={" ri-wallet-3-line text-danger"}></i> Set card as default
                </button>
              </div>
            ) : null} */}
          </ModalBody>

          <Col lg={12} xl={12} xxl={12}>
            <Card>
              <CardHeader>Edit card</CardHeader>
              <CardBody>
                <Row>
                  <Col xxl={12} lg={12} xl={12}>
                    <CardBody>
                      <TabContent activeTab={topBorderTab} className="text-muted">
                        <TabPane tabId="1" id="nav-border">
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              validateCard.handleSubmit();
                            }}
                          >
                            <Row>
                              <SelectField
                                md="6"
                                mb="mb-3"
                                onChange={validation.handleChange}
                                validation={validation}
                                onBlur={validation.handleBlur}
                                placeholder="select..."
                                label="Select type"
                                name="type"
                                optional={true}
                                defaultValue={productState.find((c) => c.value === validation.values.type)}
                                key={validation.type}
                                options={productState}
                              />

                              <TextField
                                md="6"
                                mb="mb-3"
                                value={validation.values.accountNumber || ""}
                                onChange={(e) => {
                                  console.log(e.target.value, "valuess");

                                  const value = e.target.value;

                                  validation.setFieldValue("accountNumber", value);
                                  validation.setFieldValue("accountNumber", value);
                                }}
                                validation={validation}
                                onBlur={validation.handleBlur}
                                placeholder="Enter the acccount number"
                                label="Account number"
                                name="accountNumber"
                              />

                              <div className="mb-3">
                                <TextField
                                  md="12"
                                  mb="mb-3"
                                  value={validation.values.description || ""}
                                  onChange={validation.handleChange}
                                  validation={validation}
                                  onBlur={validation.handleBlur}
                                  label="Description"
                                  name="description"
                                  placeholder="eg. my mtn wallet"
                                />
                              </div>
                            </Row>
                            <ModalFooter>
                              <button type="submit" className="btn btn-success btn-label right ms-auto nexttab">
                                <i className="ri-send-plane-2-line label-icon align-middle fs-16 ms-2"></i>
                                {validateCard.isSubmitting ? "PLEASE WAIT" : "CONFIRM"}
                              </button>
                            </ModalFooter>
                          </Form>

                          {successMsg ? (
                            <MsgToast msg={successMsg} color="success" icon="ri-checkbox-circle-line" />
                          ) : null}

                          <ProccessingLoader alert={alert} setAlert={setAlert} />
                        </TabPane>

                        <TabPane tabId="2" id="nav-border-justified-profile">
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                            }}
                          >
                            <Row>
                              <TextField
                                md="12"
                                mb="mb-3"
                                value={validation.values.otp_code || ""}
                                onChange={validation.handleChange}
                                validation={validation}
                                onBlur={validation.handleBlur}
                                placeholder="Enter the otp code"
                                label="Enter the otp code sent to your phone"
                                name="otp_code"
                              />
                            </Row>
                            <ModalFooter>
                              <Button
                                color="light"
                                onClick={() => {
                                  topBordertoggle("1");
                                }}
                              >
                                go back
                              </Button>

                              <button type="submit" className="btn btn-success btn-label right ms-auto nexttab">
                                <i className="ri-send-plane-2-line label-icon align-middle fs-16 ms-2"></i>
                                SUBMIT
                              </button>
                            </ModalFooter>
                          </Form>

                          <small className="d-flex justify-content-center">
                            <Countdown date={Date.now() + 50000} renderer={renderer} />
                          </small>
                        </TabPane>

                        <TabPane tabId="3" id="nav-border-justified-messages"></TabPane>
                      </TabContent>
                    </CardBody>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={isDefaultCardOpen}
        role="dialog"
        autoFocus={true}
        centered
        id="removeItemModal"
        toggle={() => {
          setIsDefaultCardOpen(false);
        }}
      >
        <ModalHeader
          toggle={() => {
            setIsDefaultCardOpen(false);
          }}
        ></ModalHeader>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/rdfmytjv.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">Are you Sure You want to set this card as default ?</p>
            </div>
          </div>

          <ModalFooter className="mt-6">
            {isSettingDefault ? (
              <Button disabled={isSettingDefault} color="info" className="btn-load">
                <span className="d-flex align-items-center">
                  <span className="flex-grow-1 me-2">setting default, please wait...</span>
                  <Spinner size="sm" type="grow" className="flex-shrink-0" role="status">
                    setting default, please wait...
                  </Spinner>
                </span>
              </Button>
            ) : (
              <button
                onClick={() => {
                  setCardDefaultHandler(cardRef);
                }}
                type="submit"
                className="btn btn-primary btn-label right ms-auto nexttab"
              >
                <i className="ri-send-plane-line label-icon align-middle fs-16 ms-2"></i>
                Yes, Set as default
              </button>
            )}
          </ModalFooter>
        </ModalBody>
        {successMsg ? <MsgToast msg={successMsg} color="success" icon="ri-checkbox-circle-line" /> : null}
      </Modal>

      <ProccessingLoader alert={alert} setAlert={setAlert} />

      <DeleteModal
        show={isDeleteModalOpen}
        onCloseClick={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
        onDeleteClick={() => handleCardDelete(cardRef)}
      />

      {successMsg ? <MsgToast msg={successMsg} color="success" icon="ri-checkbox-circle-line" /> : null}

      {/* {isCardDeleted ? <MsgToast msg={isCardDeleted} color="success" icon="ri-checkbox-circle-line" /> : null} */}
    </React.Fragment>
  );
};

export default WalletCards;
