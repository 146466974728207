import axios from "axios";
// import Router from "next/router";
import URLbaseAPI from "./URLbaseAPI";

export const getAuthBearer = () => {
  const access_token = localStorage.getItem("access_token");
  return { token: access_token };
};
// default
axios.defaults.baseURL = URLbaseAPI;

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  async (error) => {
    const token_not_valid = error?.response?.data?.detail;

    const signatureExpired = error?.response?.data;

    console.log(error.response, "MESSAGE__ERROR_AXIOS_INTECEPssssssss");
    let errMsg = "Authentication credentials were not provided";

    const isNetworkError = error.message === "Network Error";

    // if network error occured
    if (isNetworkError) {
      error.isNetworkError = true;
    }

    if (signatureExpired === "Thou not allowed here.") {
      console.log("SIGNATURE__EXPIRED");
      localStorage.clear();
      window.location.href = "/login";
    }

    // use to log current device out when account is logged in on different device
    if (signatureExpired === "thou not allowed.") {
      console.log("SIGNATURE__EXPIRED");
      localStorage.clear();
      window.location.href = "/login";
    }

    if (signatureExpired === "Signature has expired") {
      console.log("SIGNATURE__EXPIRED");
      localStorage.clear();
      window.location.href = "/login";
    }

    if (token_not_valid === errMsg) {
      console.log("INSIDDDDDDDDDDDDDDDDDDDD__NO_aUTH");
      localStorage.clear();
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

axios.interceptors.request.use(
  (config) => {
    const { token } = getAuthBearer();

    if (token) config.headers["X-Snp-Auth"] = `Bearer ${token}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  //axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  axios.defaults.headers.common["X-Snp-Auth"] = "Bearer " + token;
  axios.defaults.headers["X-Snp-Auth"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */
  get = (url, params) => {
    let response;
    let paramKeys = [];
    const { token } = getAuthBearer();

    console.log(token, "INSIDEEEe___TOKENENE");

    if (!token) return;

    if (params) {
      Object.keys(params).map((key) => {
        paramKeys.push(key + "=" + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };
  /**
   * post given data to url
   */
  post = (url, data) => {
    return (data = axios.post(url, data));
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}

export { APIClient, setAuthorization };
