import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN, GET_USER_ORG } from "./actionTypes";
import {
  apiError,
  loginFailed,
  loginSuccess,
  logoutUserSuccess,
  getUserOrganisation,

  // getUserOrganisationFailure,
  // getUserOrganisationSuccess
} from "./actions";

import { getProfile } from "../profile/actions";

//Include Both Helper File with needed methods
import { getOrganisationList, logOutUser, postGoogleAuth, postLogin } from "../../../helpers/backend_helper";

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_API_URL) {
      const response = yield call(postLogin, {
        username: user.username,
        password: user.password,
      });

      console.log(response, "LOGIN RESPONSE");

      if (response.error) {
        yield put(loginFailed(response));
      } else {
        if (response.status === 200) {
          yield sessionStorage.setItem("authUser", JSON.stringify(response));
          yield sessionStorage.setItem("org", JSON.stringify({ profile_org_list: response.profile_org_list }));
          history.replace("/analytics");

          // displatch get organisation action
          // yield put(getUserOrganisation(history));
        } else {
          yield put(loginFailed(response));
        }
      }
    }
  } catch (error) {
    console.log("ERROR RESPONSE: ", error.response);

    yield put(loginFailed(error));
  }
}

function* logoutUser() {
  try {
    console.log("loggint__outt");
    const response = yield call(logOutUser);
    yield put(logoutUserSuccess(LOGOUT_USER, true));
    localStorage.clear();
  } catch (error) {
    console.log(error, "LOGGIN__EERRRO");
    yield put(logoutUserSuccess(LOGOUT_USER, true));
    localStorage.clear();

    yield put(apiError(LOGOUT_USER, error));
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    const response = yield call(postGoogleAuth, data);
    sessionStorage.setItem("authUser", JSON.stringify(response));
    yield put(loginSuccess(response));
    history.replace("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getUserOrganisationWorker({ payload: { history } }) {
  try {
    // console.log("getting all the getOrganisations")
    if (sessionStorage.getItem("authUser")) {
      // console.log("session storage set for auth user")
      // We Need to Fetch the Organisation the User belongs to
      console.log("calling getOrganisationList", sessionStorage.getItem("authUser"));
      const r = yield call(getOrganisationList);
      // console.log("ORGANISATION", r)
      if (r.error) {
        yield put(loginFailed(r));
      } else {
        yield put(loginSuccess(r));
        sessionStorage.setItem("org", JSON.stringify(r));
        console.log("INSIDEE WORRKER", r);
        history.replace("/dashboard");
      }
    }
  } catch (error) {
    yield put(loginFailed(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(GET_USER_ORG, getUserOrganisationWorker);
}

export default authSaga;
